import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useClient, GET_MANY, DELETE } from '@peracto/client'
import { toast } from 'react-toastify'
import { bool } from 'prop-types'
import { FormActions, Modal, Form } from '@peracto/peracto-ui'
import { LocationFormContent } from './LocationFormContent'
import * as yup from 'yup'

const LocationsForm = ({
    data,
    modeAdd = false,
    modeEdit = false,
    onDuplicate = () => {},
    schema,
    ...props
}) => {
    const { client } = useClient()
    const [countries, setCountries] = useState()
    const [redirect, setRedirect] = useState()
    const [modalOpen, toggleModal] = useState(false)
    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)

    const URLschema = yup.object().shape({
        ...schema.fields,
        bookOnlineAppointmentUrl: yup.string().url(),
        bookBranchAppointmentUrl: yup.string().url(),
    })

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    const fetchCountries = async () => {
        try {
            const { data: countryData, response: countryResponse } = await client(
                GET_MANY,
                'countries',
                {
                    id: 'countries',
                }
            )

            if (countryResponse.status === 404) {
                setRedirect('/locations')
            }

            setCountries(countryData)
        } catch (e) {
            console.error(e)
            setRedirect('/locations')
        }
    }

    const onDelete = async () => {
        try {
            await client(DELETE, 'locations', {
                id: data.id,
            })

            toast.success('Location deleted successfully!')
            setRedirect('/locations')
        } catch (e) {
            console.error(e)
            toast.error('Whoops, there was a problem...')
        }
    }

    useEffect(() => {
        fetchCountries()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {redirect && <Redirect to={redirect} />}

            <FormActions
                children={
                    modeEdit ? (
                        <>
                            <button
                                className="btn btn--outline-primary p-0"
                                onClick={() => setShowDuplicateDialog(true)}
                            >
                                <FontAwesomeIcon icon={['far', 'copy']} className="mr-2" />
                                Duplicate
                            </button>
                            <button
                                className="btn btn--outline-primary text-danger"
                                onClick={() => toggleModal(!modalOpen)}
                            >
                                <FontAwesomeIcon icon={['far', 'trash']} className="mr-2" />
                                Delete Location
                            </button>
                        </>
                    ) : null
                }
            />
            <Form autoComplete="false" values={data} schema={URLschema} {...props}>
                <LocationFormContent days={days} countries={countries} />
            </Form>

            {modeEdit && (
                <>
                    <Modal
                        isVisible={modalOpen}
                        title="Delete User"
                        close={() => toggleModal(!modalOpen)}
                        buttons={[
                            {
                                type: 'btn-outline-secondary',
                                text: 'Close',
                                action: () => toggleModal(!modalOpen),
                            },
                            {
                                type: 'btn-danger',
                                text: 'Delete Location',
                                action: () => onDelete(),
                            },
                        ]}
                    >
                        <FontAwesomeIcon
                            icon={['far', 'exclamation-triangle']}
                            size="4x"
                            className="d-block mb-4"
                        />
                        Are you sure you would like to permanently delete {data.name}? Deleted
                        locations cannot be recovered.
                    </Modal>

                    <Modal
                        isVisible={showDuplicateDialog}
                        title="Duplicate Location"
                        close={() => setShowDuplicateDialog(false)}
                        buttons={[
                            {
                                type: 'btn-outline-secondary',
                                text: 'Close',
                                action: () => setShowDuplicateDialog(false),
                            },
                            {
                                type: 'btn-success',
                                text: 'Duplicate Location',
                                action: () => onDuplicate(data),
                            },
                        ]}
                    >
                        <FontAwesomeIcon
                            icon={['far', 'info-circle']}
                            size="4x"
                            className="d-block mb-4"
                        />
                        Are you sure you would like to duplicate this location?
                    </Modal>
                </>
            )}
        </>
    )
}

LocationsForm.propTypes = {
    modeAdd: bool,
    modeEdit: bool,
}

export default LocationsForm
