import React from "react";
import { ThemeProvider } from "styled-components";
import { theme as chakraTheme } from "@chakra-ui/core";

import Peracto from "@peracto/peracto";
import Config from "@peracto/peracto-config";
import PeractoUser from "@peracto/peracto-user";
import PeractoCatalogue from "@peracto/peracto-catalogue";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";
import PeractoLocations from "@peracto/peracto-locations";
import { LocationsForm } from "./packages/Locations";
import PeractoPromotions from "@peracto/peracto-promotions";
import compact from "lodash/compact";
import { Carbon } from "@peracto/fe-library";

import theme from "./theme";
import blocks from "./blocks";

const sizes = {
  sm: ["520px", "576px"],
  md: ["720px", "768px"],
  lg: ["960px", "992px"],
  xl: ["1210px", "1210px"],
};

export const breakpoints = Object.values(sizes).reduce((bp, size) => {
  bp.push(size[1]);
  return bp;
}, []);

export const containers = Object.values(sizes).reduce((bp, size) => {
  bp.push(size[0]);
  return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

containers.sm = containers[0];
containers.md = containers[1];
containers.lg = containers[2];
containers.xl = containers[3];

const brandColors = {
  Primary: "#6B1D2F",
  Secondary: "#3D3935",
  Tertiary: "#C5B683",
  "Grey 80": "#CBCACA",
  "Grey 90": "#F2F2F2",
  White: "#FFFFFF",
  Black: "#000000",
};

const benchmarxTheme = {
  ...chakraTheme,
  breakpoints,
  sizes: {
    ...Carbon.Theme.sizes,
    containers,
    breakpoints: Object.entries(sizes).reduce((bp, size) => {
      bp[size[0]] = size[1][1];
      bp[`${size[0]}Raw`] = Number.parseInt(size[1][1].replace("px", ""), 10);
      return bp;
    }, {}),
  },
  fonts: {
    ...theme.fonts,
    heading: "Quicksand, sans-serif",
    body: "Quicksand, sans-serif",
    mono: "Quicksand, sans-serif",
  },
  colors: {
    ...theme.colors,
    brand: brandColors,
  },
};

const config = new Config({
  api: process.env.REACT_APP_API,
  modules: [
    PeractoUser(),
    PeractoCommon(),
    PeractoCatalogue(),
    PeractoLocations({ LocationsForm }),
    PeractoPromotions(),
    PeractoContent(),
  ],
  editorConfig: {
    theme: benchmarxTheme,
    colorOptions: compact(
      Object.entries(brandColors).map(([label, value]) => {
        // Filter white, because it's used by the default colours and we don't want to duplicate it.
        if (value === "#FFFFFF") return null;
        return { label, value };
      })
    ),
    locales: ["en_GB", "es_ES", "fr_FR"],
    blocks,
    inputTypes: {},
  },
  algolia: {
      applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
      searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
      indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
  },
  features: {
    shippingServices: {
      deliveryDays: true,
    },
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <Peracto config={config} />
  </ThemeProvider>
);

export default App;
