import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const defaultTranslations = {
    pluginName: 'Products',
    pluginDescription: 'Display products returned from Algolia.',
}

export const defaultSettings = {
    Controls: () => <>Controls for this plugin were not provided</>,
    Renderer: () => <>Renderer for this plugin was not provided</>,
    translations: defaultTranslations,
    IconComponent: <FontAwesomeIcon icon={['far', 'box-full']} size="3x" />,
}
