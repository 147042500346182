import {
    Container,
    Text,
    Image,
    Video,
    YouTube,
    Embed,
    Map,
    Form,
    Buttons,
    MenuItems,
    Carousel,
} from "@peracto/peracto-blocks";

import BenchProducts from './BenchProducts'


export default {
    plugins: {
        content: [
            Text,
            Image,
            Video,
            YouTube,
            Embed,
            Map,
            Form,
            Buttons,
            MenuItems,
            Carousel,
            BenchProducts
        ],
        layout: [Container],
    },
};
